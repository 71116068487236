import React, { useEffect, useState } from 'react';
import { fetchSpotifyArtistData } from '../utils/spotify';

const SpotifyTest = () => {
  const [monthlyListeners, setMonthlyListeners] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const testArtist = async () => {
      setLoading(true);
      setError(null);
      try {
        // Test with a known artist ID (Drake)
        const artistId = '3TVXtAsR1Inumwj472S9r4';
        const artistData = await fetchSpotifyArtistData(artistId);
        
        if (artistData) {
          console.log('Artist data:', artistData);
          setMonthlyListeners(artistData.monthlyListeners);
        } else {
          throw new Error('Failed to fetch artist data');
        }
      } catch (err) {
        console.error('Test error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    testArtist();
  }, []);

  return (
    <div className="p-6">
      <h2 className="text-xl mb-4">Spotify Monthly Listeners Test</h2>
      {loading && <p>Loading...</p>}
      {error && (
        <div className="text-red-500">
          <p>Error: {error}</p>
          <p className="text-sm mt-2">Check the browser console for more details.</p>
        </div>
      )}
      {monthlyListeners && (
        <div>
          <p>Monthly Listeners: {monthlyListeners.toLocaleString()}</p>
        </div>
      )}
    </div>
  );
};

export default SpotifyTest;
