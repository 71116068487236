import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { getSpotifyAccessToken, fetchSpotifyArtistData } from '../utils/spotify';

// Fetch Instagram followers using RapidAPI
const fetchInstagramData = async (username) => {
  try {
    const response = await fetch(`https://instagram-bulk-profile-scrapper.p.rapidapi.com/clients/api/ig/ig_profile?ig=${username}`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
        'X-RapidAPI-Host': 'instagram-bulk-profile-scrapper.p.rapidapi.com'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Instagram data');
    }

    const data = await response.json();
    return {
      followers: data.followers || 0,
      username: data.username || username
    };
  } catch (error) {
    console.error('Error fetching Instagram data:', error);
    return null;
  }
};

const extractSpotifyArtistId = (url) => {
  // Handle full Spotify URLs
  if (url.includes('spotify.com/artist/')) {
    const match = url.match(/artist\/([a-zA-Z0-9]+)/);
    if (match && match[1]) {
      return match[1];
    }
  }
  
  // Handle direct artist IDs
  if (url.match(/^[a-zA-Z0-9]{22}$/)) {
    return url;
  }
  
  return null;
};

// Fetch Spotify data using RapidAPI
const fetchSpotifyData = async (artistId) => {
  try {
    const response = await fetch(`https://spotify-statistics-and-stream-count.p.rapidapi.com/artist/${artistId}`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
        'X-RapidAPI-Host': 'spotify-statistics-and-stream-count.p.rapidapi.com'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Spotify data');
    }

    const data = await response.json();
    return {
      monthlyListeners: data.monthly_listeners || data.monthlyListeners || 0,
      name: data.name || '',
      followers: data.followers || 0
    };
  } catch (error) {
    console.error('Error fetching Spotify data:', error);
    return null;
  }
};

export const saveSignup = async (email, interests) => {
  try {
    console.log('Starting signup process with data:', { email, interests });
    const signupRef = collection(db, 'signups');
    
    // Initialize signup data
    const signupData = {
      email,
      timestamp: serverTimestamp(),
      instagram: interests.instagram || false,
      instagramHandle: interests.instagramHandle || '',
      donateClothes: interests.donateClothes || false,
      contribute: interests.contribute || false,
      artist: interests.spotify || false,
      source: 'web',
      isInfluencer: false
    };

    // Handle Instagram data from user input
    if (interests.instagram && interests.instagramHandle && interests.instagramFollowers) {
      signupData.instagramData = {
        followers: parseInt(interests.instagramFollowers) || 0,
        username: interests.instagramHandle.replace('@', '')
      };
      // Mark as influencer if more than 10k followers
      if (signupData.instagramData.followers >= 10000) {
        signupData.isInfluencer = true;
      }
    }

    // Fetch Spotify data if artist URL is provided
    if (interests.spotify && interests.spotifyUrl) {
      const artistId = extractSpotifyArtistId(interests.spotifyUrl);
      if (artistId) {
        const spotifyData = await fetchSpotifyData(artistId);
        if (spotifyData) {
          signupData.spotifyData = spotifyData;
          // Mark as influencer if more than 10k monthly listeners
          if (spotifyData.monthlyListeners >= 10000) {
            signupData.isInfluencer = true;
          }
        }
      }
    }

    console.log('Prepared signup data:', signupData);

    // Add to Firebase
    const docRef = await addDoc(signupRef, signupData);
    console.log('Successfully added document with ID:', docRef.id);
    return { success: true };
  } catch (error) {
    console.error('Detailed error in saveSignup:', {
      errorMessage: error.message,
      errorCode: error.code,
      errorStack: error.stack
    });
    return { success: false, error: error.message };
  }
};
