const RAPIDAPI_SPOTIFY_URL = 'https://spotify-statistics-and-stream-count.p.rapidapi.com';

// Extract Spotify artist ID from URL or ID string
export const extractSpotifyArtistId = (input) => {
  if (!input) return null;

  // Remove any trailing slashes and query parameters
  input = input.split('?')[0].replace(/\/$/, '');

  // If it's a full Spotify URL
  if (input.includes('spotify.com/artist/')) {
    const match = input.match(/artist\/([a-zA-Z0-9]+)/);
    return match ? match[1] : null;
  }

  // If it's just the ID (22 characters)
  if (/^[a-zA-Z0-9]{22}$/.test(input)) {
    return input;
  }

  return null;
};

// Fetch artist data from RapidAPI
export const fetchSpotifyArtistData = async (artistId) => {
  if (!artistId) return null;

  try {
    const rapidApiKey = process.env.REACT_APP_RAPIDAPI_KEY;
    if (!rapidApiKey) {
      throw new Error('RapidAPI key not found');
    }

    const response = await fetch(`${RAPIDAPI_SPOTIFY_URL}/artist/${artistId}`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': rapidApiKey,
        'X-RapidAPI-Host': 'spotify-statistics-and-stream-count.p.rapidapi.com'
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch artist data: ${response.status}`);
    }

    const data = await response.json();
    
    // Return comprehensive artist data
    return {
      id: artistId,
      name: data.name || '',
      monthlyListeners: data.monthly_listeners || data.monthlyListeners || 0,
      followers: data.followers || 0,
      popularity: data.popularity || 0,
      totalPlaylists: data.total_playlists || data.totalPlaylists || 0,
      genres: data.genres || [],
      images: data.images || [],
      // Additional data from RapidAPI
      worldRank: data.world_rank || data.worldRank,
      countryRank: data.country_rank || data.countryRank,
      topCities: data.top_cities || data.topCities || [],
      recentlyPlayed: data.recently_played_tracks || data.recentlyPlayedTracks || [],
      lastUpdated: new Date()
    };
  } catch (error) {
    console.error('Error fetching Spotify artist data:', error);
    return null;
  }
};
