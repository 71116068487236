import React, { useState, useEffect, lazy, Suspense, memo } from 'react';
import { ArrowUpRight, Mouse, Bell, X, MessageSquare, Recycle, Palette, Leaf, ShieldCheck, Factory, HeartHandshake, Mail, Sparkles, Shirt } from 'lucide-react';
import ErrorBoundary from './components/ErrorBoundary';
import { saveSignup } from './services/firebaseService';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import { extractSpotifyArtistId, getSpotifyAccessToken, fetchSpotifyArtistData } from './utils/spotify';
import SpotifyTest from './components/SpotifyTest';

const AdminSignups = lazy(() => import('./pages/AdminSignups'));

const SocialMeta = () => (
  <>
    <meta property="og:title" content="REPRINT.ink - En revolusjon innen merch" />
    <meta property="og:description" content="Vi transformerer glemte plagg til nye uttrykk. Klær skal ikke definere oss, de skal forene oss." />
    <meta property="og:image" content="/og-image.png" />
    <meta property="og:url" content="https://reprint.ink" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta property="og:site_name" content="REPRINT.ink" />
    <meta name="twitter:image:alt" content="REPRINT.ink - Transformerer mote" />
  </>
);

const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-[#00FFFF]"></div>
  </div>
);

const Tooltip = ({ text, children }) => (
  <div className="group relative inline-block">
    {children}
    <div className="opacity-0 bg-black/90 text-white text-sm rounded-lg py-2 px-3 absolute z-10 
                  bottom-full left-1/2 -translate-x-1/2 mb-2 w-64 md:w-72
                  group-hover:opacity-100 transition-opacity duration-300
                  pointer-events-none backdrop-blur-sm">
      {text}
      <div className="border-t-[6px] border-t-black/90 border-l-[6px] border-l-transparent 
                    border-r-[6px] border-r-transparent absolute top-full left-1/2 -translate-x-1/2">
      </div>
    </div>
  </div>
);

const NotificationSignup = memo(({ isOpen, setIsOpen }) => {
  const [formData, setFormData] = useState({
    email: '',
    optIns: {
      instagram: false,
      instagramHandle: '',
      donateClothes: false,
      contribute: false,
      artist: false
    },
    spotify: '',
    timestamp: null
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Validate email
    if (!formData.email) {
      setError('Vennligst fyll inn e-postadressen din.');
      setLoading(false);
      return;
    }

    // Validate Instagram handle if Instagram is selected
    if (formData.optIns.instagram && !formData.optIns.instagramHandle) {
      setError('Vennligst fyll inn Instagram-brukernavnet ditt.');
      setLoading(false);
      return;
    }

    // Validate Spotify if artist is selected
    if (formData.optIns.artist && !formData.spotify) {
      setError('Vennligst fyll inn Spotify-lenken eller artistnavnet ditt.');
      setLoading(false);
      return;
    }

    try {
      console.log('Submitting form with data:', formData);
      const result = await saveSignup(formData.email, {
        instagram: formData.optIns.instagram,
        instagramHandle: formData.optIns.instagramHandle.startsWith('@') 
          ? formData.optIns.instagramHandle.substring(1) 
          : formData.optIns.instagramHandle,
        donateClothes: formData.optIns.donateClothes,
        contribute: formData.optIns.contribute,
        spotify: formData.optIns.artist
      });

      console.log('Signup result:', result);

      if (!result.success) {
        throw new Error(result.error || 'Failed to save signup');
      }
      
      setSuccess(true);
      setThankYouMessage('Tusen takk for at du vil være med på reisen vår! 🙌\n\nVi tar kontakt med mer informasjon om vekslingskonseptet vårt.');
      setFormData({
        email: '',
        optIns: {
          instagram: false,
          instagramHandle: '',
          donateClothes: false,
          contribute: false,
          artist: false
        },
        spotify: '',
        timestamp: null
      });
    } catch (error) {
      console.error('Detailed form submission error:', {
        message: error.message,
        code: error.code,
        stack: error.stack
      });
      setError(`Det oppstod en feil ved innsending: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 md:inset-auto md:bottom-8 md:right-8 md:max-w-md z-50 p-4 md:p-0">
      <div className="bg-neutral-900/95 backdrop-blur-sm rounded-2xl p-6 shadow-xl border border-white/10 relative overflow-hidden">
        <div className="absolute inset-0 opacity-5 bg-center bg-cover" style={{ backgroundImage: 'url(/bg1tease.png)' }}></div>
        <div className="relative">
          {success ? (
            <div className="text-center py-8">
              <h3 className="text-2xl font-light mb-4">Takk!</h3>
              <p className="text-white/70 whitespace-pre-line">{thankYouMessage}</p>
              <button
                onClick={() => {
                  setSuccess(false);
                  setIsOpen(false);
                }}
                className="mt-6 px-6 py-2 bg-white/5 hover:bg-white/10 border border-white/20 
                       hover:border-white/30 rounded-lg text-white/90 hover:text-white
                       transition-all text-sm"
              >
                Lukk
              </button>
            </div>
          ) : (
            <>
              <button 
                onClick={() => setIsOpen(false)}
                className="absolute top-4 right-4 text-white/50 hover:text-white"
              >
                <X size={16} />
              </button>
              <div className="space-y-6">
                <div className="flex items-start gap-3">
                  <Mail className="text-[#00FFFF] mt-1" size={24} />
                  <div>
                    <h3 className="text-white/90 font-light text-2xl">Bli med på bevegelsen</h3>
                    <p className="text-white/50 text-base mt-2">Legg igjen e-posten din så gir vi beskjed</p>
                  </div>
                </div>

                <div className="space-y-4">
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      email: e.target.value
                    }))}
                    placeholder="Din e-post"
                    required
                    className="w-full bg-white/5 border border-white/10 rounded-lg 
                           px-4 py-2 text-base
                           text-white placeholder:text-white/30 
                           focus:outline-none focus:border-white/20"
                  />

                  <div className="space-y-4">
                    <p className="text-white/50 text-sm mt-4">I tillegg vil vi <span className="text-[#FFFF00] font-medium">gjerne</span> høre om du:</p>

                    <Tooltip text="Bli med i vår eksklusive Instagram-gruppe">
                      <label className="flex items-center gap-2 cursor-pointer group">
                        <input
                          type="checkbox"
                          checked={formData.optIns.instagram}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            optIns: {
                              ...prev.optIns,
                              instagram: e.target.checked
                            }
                          }))}
                          className="w-4 h-4 rounded border-white/20 bg-white/5 
                                 text-[#FF00FF] focus:ring-0 focus:ring-offset-0"
                        />
                        <span className="text-white/70 text-sm group-hover:text-white/90">
                          Vil bli med i gruppechaten vår på Instagram
                        </span>
                      </label>
                    </Tooltip>

                    {formData.optIns.instagram && (
                      <div className="pl-6">
                        <input
                          type="text"
                          value={formData.optIns.instagramHandle}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            optIns: {
                              ...prev.optIns,
                              instagramHandle: e.target.value
                            }
                          }))}
                          placeholder="@brukernavn"
                          className="w-full bg-white/5 border border-white/10 rounded-lg 
                                 px-4 py-2 text-base
                                 text-white placeholder:text-white/30 
                                 focus:outline-none focus:border-white/20"
                        />
                      </div>
                    )}

                    <Tooltip text="Bærekraftig merch for artister">
                      <label className="flex items-center gap-2 cursor-pointer group">
                        <input
                          type="checkbox"
                          checked={formData.optIns.artist}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            optIns: {
                              ...prev.optIns,
                              artist: e.target.checked
                            }
                          }))}
                          className="w-4 h-4 rounded border-white/20 bg-white/5 
                                 text-[#FF00FF] focus:ring-0 focus:ring-offset-0"
                        />
                        <span className="text-white/70 text-sm group-hover:text-white/90">
                          Er artist og vil høre mer om bærekraftig merch
                        </span>
                      </label>
                    </Tooltip>

                    {formData.optIns.artist && (
                      <div className="pl-6 space-y-2">
                        <p className="text-white/50 text-sm">
                          Vi jobber med å gjøre det enklere for artister å selge bærekraftig merch. 
                          Legg inn Spotify-profilen din eller artist ID!
                        </p>
                        <input
                          type="text"
                          value={formData.spotify || ''}
                          onChange={(e) => setFormData(prev => ({
                            ...prev,
                            spotify: e.target.value
                          }))}
                          placeholder="spotify.com/artist/... eller artist ID"
                          className="w-full bg-white/5 border border-white/10 rounded-lg 
                                 px-4 py-2 text-base
                                 text-white placeholder:text-white/30 
                                 focus:outline-none focus:border-white/20"
                        />
                      </div>
                    )}
                  </div>

                  <Tooltip text="Få mulighet til å bytte inn klær">
                    <label className="flex items-center gap-2 cursor-pointer group">
                      <input
                        type="checkbox"
                        checked={formData.optIns.donateClothes}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          optIns: {
                            ...prev.optIns,
                            donateClothes: e.target.checked
                          }
                        }))}
                        className="w-4 h-4 rounded border-white/20 bg-white/5 
                               text-[#00FFFF] focus:ring-0 focus:ring-offset-0"
                      />
                      <span className="text-white/70 text-sm group-hover:text-white/90">
                        Veksle inn klær for merch <span className="opacity-50">*hysj*</span>
                      </span>
                    </label>
                  </Tooltip>

                  <Tooltip text="Del dine tanker og ideer med oss">
                    <label className="flex items-center gap-2 cursor-pointer group">
                      <input
                        type="checkbox"
                        checked={formData.optIns.contribute}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          optIns: {
                            ...prev.optIns,
                            contribute: e.target.checked
                          }
                        }))}
                        className="w-4 h-4 rounded border-white/20 bg-white/5 
                               text-[#FFFF00] focus:ring-0 focus:ring-offset-0"
                      />
                      <span className="text-white/70 text-sm group-hover:text-white/90">
                        Har lyst å engasjere meg og bidra på andre måter
                      </span>
                    </label>
                  </Tooltip>

                  {error && (
                    <p className="text-red-400 text-sm">{error}</p>
                  )}

                  <button 
                    onClick={handleSubmit}
                    disabled={loading}
                    className="w-full bg-[#FFFF00] text-black font-medium 
                           px-6 py-3 rounded-lg text-lg
                           hover:bg-[#FFFF00]/90 transition-all duration-300
                           scale-100 hover:scale-[1.02]
                           disabled:opacity-50 disabled:hover:scale-100"
                  >
                    {loading ? 'sender...' : 'Bli med oss!'}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

const Footer = ({ handleFooterSubmit, footerEmail, setFooterEmail, footerError }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleFooterSubmit(e);
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleFooterSubmit(e);
    }
  };

  return (
    <footer className="bg-black py-16 select-none">
      <div className="max-w-7xl mx-auto px-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16">
          <div className="space-y-4">
            <h4 className="font-mono text-sm mb-4 tracking-wider">KONTAKT</h4>
            <div className="flex items-center gap-3">
              <a href="mailto:hei@reprint.ink" className="text-neutral-500 text-sm hover:text-white transition-colors">
                hei@reprint.ink
              </a>
              <a 
                href="mailto:hei@reprint.ink"
                className="text-[#00FFFF] hover:opacity-50 transition-opacity"
              >
                <Mail size={14} />
              </a>
            </div>
          </div>
          <div>
            <h4 className="font-mono text-sm mb-4 tracking-wider">ADRESSE</h4>
            <p className="text-neutral-500 text-sm">JOHAN BERNTSENSVEI 65</p>
            <p className="text-neutral-500 text-sm">BERGEN, NORGE</p>
          </div>
          <div>
            <h4 className="font-mono text-sm mb-4 tracking-wider">SOSIALE MEDIER</h4>
            <a href="https://www.instagram.com/reprint.ink" className="text-[#00FFFF] text-sm hover:opacity-50 transition-opacity block">INSTAGRAM</a>
            <a href="Https://www.facebook.com/reprint.ink" className="text-[#FF00FF] text-sm hover:opacity-50 transition-opacity block">FACEBOOK</a>
            <a href="https://www.tiktok.com/@reprint.ink" className="text-[#FFFF00] text-sm hover:opacity-50 transition-opacity block">TIKTOK</a>
          </div>
          <div>
            <h4 className="font-mono text-sm mb-4 tracking-wider">NYHETSBREV</h4>
            <form onSubmit={handleSubmit} className="flex gap-2">
              <input
                type="email"
                name="footer-email"
                autoComplete="email"
                placeholder="DIN E-POST"
                value={footerEmail}
                onChange={(e) => setFooterEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                className="flex-1 bg-transparent border border-white/20 rounded-lg 
                       px-4 py-3 text-sm text-left text-neutral-500
                       focus:outline-none hover:border-white/30
                       transition-all"
              />
              <button 
                type="submit"
                className="bg-white/5 hover:bg-white/10 border border-white/20 
                       hover:border-white/30 rounded-lg px-4
                       transition-all"
              >
                <Mail size={16} className="text-[#00FFFF]" />
              </button>
            </form>
            {footerError && (
              <p className="text-red-400 text-sm">{footerError}</p>
            )}
          </div>
        </div>
        <div className="border-t border-white/10 pt-8 text-center">
          <p className="text-neutral-500 text-sm" onClick={() => window.location.href='https://reprint-ink.web.app/admin'} style={{ cursor: 'default' }}> 2024 REPRINT.ink by <span style={{ cursor: 'pointer' }}>WWW A/S</span></p>
        </div>
      </div>
    </footer>
  );
};

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);
  const [footerEmail, setFooterEmail] = useState('');
  const [footerError, setFooterError] = useState('');

  const handleFooterSubmit = async (event) => {
    event.preventDefault();
    console.log('Form submitted', footerEmail);

    if (!footerEmail) {
      setFooterError('Vennligst skriv inn e-postadressen din');
      return;
    }

    try {
      await addDoc(collection(db, 'newsletterSignups'), {
        email: footerEmail,
        timestamp: serverTimestamp(),
        source: 'web_footer'
      });

      setFooterEmail('');
      setFooterError('');
      alert('Takk for påmeldingen!');
    } catch (error) {
      console.error('Error adding document: ', error);
      setFooterError('Beklager, noe gikk galt. Vennligst prøv igjen.');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolledToBottom = window.innerHeight + window.pageYOffset >= document.documentElement.scrollHeight - 100;
      if (scrolledToBottom && !hasShownPopup) {
        setIsOpen(true);
        setHasShownPopup(true);
      }

      setHasScrolled(window.scrollY > 50);
    };

    const timer = setTimeout(() => {
      if (!hasShownPopup) {
        setIsOpen(true);
        setHasShownPopup(true);
      }
    }, 4000);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, [hasShownPopup]);

  return (
    <ErrorBoundary>
      <div className="min-h-screen bg-black text-white relative">
        <Suspense fallback={null}>
          {window.location.pathname === '/admin' ? (
            <AdminSignups />
          ) : window.location.pathname === '/spotify-test' ? (
            <SpotifyTest />
          ) : (
            <>
              <div className="fixed inset-0 opacity-5 bg-center bg-cover pointer-events-none" style={{ backgroundImage: 'url(/bg1tease.png)' }}></div>
              <div className="relative">
                <main>
                  {/* Header */}
                  <header className={`fixed top-0 w-full z-50 transition-all duration-500 ${hasScrolled ? 'py-4 bg-neutral-900/90 backdrop-blur-sm' : 'py-6'}`}>
                    <div className="px-8 flex justify-between items-center">
                      <a href="/" className="group">
                        <h1 className="text-xl tracking-tighter font-mono select-none 
                                   group-hover:opacity-80 transition-opacity">
                          REPRINT<span className="font-mono">.
                            <span className="text-[#00FFFF]">i</span>
                            <span className="text-[#FF00FF]">n</span>
                            <span className="text-[#FFFF00]">k</span>
                          </span>
                        </h1>
                      </a>
                      <Mouse className="animate-bounce" size={16} />
                    </div>
                  </header>

                  {/* Main hero */}
                  <main className="min-h-screen grid grid-cols-1 md:grid-cols-2">
                    <div className="flex items-center justify-center p-12 md:p-16">
                      <div className="space-y-8">
                        <h2 className="text-7xl md:text-9xl font-black leading-none tracking-tighter select-none">
                          RE
                          <br />
                          PRINT
                          <span className="relative">
                            .
                            <span className="text-[#00FFFF]">i</span>
                            <span className="text-[#FF00FF]">n</span>
                            <span className="text-[#FFFF00]">k</span>
                          </span>
                        </h2>
                        <div className="h-px w-full bg-white/20" />
                        <p className="text-neutral-400 text-xl font-mono tracking-widest select-none">
                          FØRSTE DROP JANUAR 2025
                        </p>
                      </div>
                    </div>

                    <div className="bg-neutral-950 flex items-center p-12 md:p-16">
                      <div className="space-y-16 w-full">
                        <div className="max-w-2xl space-y-8">
                          <div>
                            <p className="text-[#FF00FF] font-mono text-sm tracking-[0.5em] mb-4 select-none">NY TILNÆRMING</p>
                            <h2 className="text-3xl md:text-5xl font-black leading-tight select-none">
                              Dette er merch som <span className="text-[#FFFF00]">må</span> skje.
                            </h2>
                          </div>
                          <div className="space-y-6">
                            <p className="text-xl md:text-2xl font-light leading-relaxed select-none text-neutral-200">
                              Fra glemte plagg til nye uttrykk.
                              <br />
                              Dette er ikke bare en mulighet — det er en nødvendighet.
                            </p>
                            <p className="text-neutral-400 text-lg leading-relaxed font-light select-none">
                              
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col gap-8">
                          <button 
                            onClick={() => setIsOpen(true)}
                            className="group flex items-center gap-3 bg-white/5 hover:bg-white/10 border border-white/10 
                                   hover:border-white/30 rounded-lg px-8 py-5 transition-all w-fit"
                          >
                            <Bell className="text-[#00FFFF]" size={24} />
                            <span className="font-mono tracking-wider text-lg">ENGASJER DEG FOR FREMTIDEN</span>
                            <ArrowUpRight className="opacity-50 group-hover:opacity-100 transition-opacity ml-auto" size={24} />
                          </button>

                          <div className="space-y-4">
                            <div className="h-px w-16 bg-white/20" />
                            <p className="font-mono text-sm tracking-[0.2em] text-neutral-400 select-none">Vi trenger en revolusjon.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>

                  {/* Dynamic Brand Teasers */}
                  <section className="w-full overflow-hidden relative">
                    <div className="flex space-x-4 px-8 py-12">
                      <div className="w-1/3 h-20 relative group cursor-pointer overflow-hidden">
                        <div className="absolute inset-0 bg-[#00FFFF] backdrop-blur-sm border border-[#00FFFF] rounded-lg opacity-10 transition-all duration-700 group-hover:opacity-20" />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-700">
                          <span className="font-mono text-[#00FFFF] tracking-[0.5em] text-xs uppercase">Snart</span>
                        </div>
                        <div className="absolute bottom-0 left-0 w-0 h-[1px] bg-[#00FFFF] group-hover:w-full transition-all duration-1000 ease-in-out" />
                      </div>

                      <div className="w-1/3 h-20 relative group cursor-pointer overflow-hidden">
                        <div className="absolute inset-0 bg-[#FF00FF] backdrop-blur-sm border border-[#FF00FF] rounded-lg opacity-10 transition-all duration-700 group-hover:opacity-20" />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-700">
                          <span className="font-mono text-[#FF00FF] tracking-[0.5em] text-xs uppercase">2025</span>
                        </div>
                        <div className="absolute bottom-0 right-0 w-0 h-[1px] bg-[#FF00FF] group-hover:w-full transition-all duration-1000 ease-in-out" />
                      </div>

                      <div className="w-1/3 h-20 relative group cursor-pointer overflow-hidden">
                        <div className="absolute inset-0 bg-[#FFFF00] backdrop-blur-sm border border-[#FFFF00] rounded-lg opacity-10 transition-all duration-700 group-hover:opacity-20" />
                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-700">
                          <span className="font-mono text-[#FFFF00] tracking-[0.5em] text-xs uppercase">Først</span>
                        </div>
                        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-0 h-[1px] bg-[#FFFF00] group-hover:w-full transition-all duration-1000 ease-in-out" />
                      </div>
                    </div>

                    <div className="text-center">
                      <button 
                        onClick={() => setIsOpen(true)}
                        className="inline-flex items-center gap-3 px-6 py-3 bg-white/5 hover:bg-white/10 
                               border border-white/10 hover:border-white/20 rounded-lg 
                               transition-all duration-500 group"
                      >
                        <span className="font-mono text-xs tracking-[0.3em] text-neutral-400 group-hover:text-white transition-colors">BLI MED FRA STARTEN</span>
                        <ArrowUpRight size={16} className="text-neutral-400 group-hover:text-white transition-colors" />
                      </button>
                    </div>
                  </section>

                  {/* Info Boxes */}
                  <section className="py-24 md:py-32 bg-neutral-950">
                    <div className="max-w-7xl mx-auto px-8">
                      <h3 className="text-lg md:text-xl font-mono mb-16 opacity-100 select-none text-center">HVORFOR REPRINT</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
                        {[
                          {
                            icon: Leaf,
                            title: "BÆREKRAFTIG",
                            desc: "Vi gir nytt liv til eksisterende plagg, reduserer avfall og miljøpåvirkning.",
                            color: "#00FFFF",
                            tagline: "FOR PLANETEN"
                          },
                          {
                            icon: Palette,
                            title: "UNIKT",
                            desc: "Hvert plagg er en kunstnerisk tolkning, en perfekt blanding av fortid og nåtid.",
                            color: "#FF00FF",
                            tagline: "FOR KREATIVITETEN"
                          },
                          {
                            icon: HeartHandshake,
                            title: "ANSVARLIG",
                            desc: "Vi skaper positive ringvirkninger i moteindustrien, ett plagg om gangen.",
                            color: "#FFFF00",
                            tagline: "FOR FREMTIDEN"
                          }
                        ].map((box, idx) => (
                          <div key={idx} 
                               className="border border-white/10 p-8 rounded-lg hover:border-white/30 
                                      transition-all hover:bg-white/5 group flex flex-col h-full"
                          >
                            <div>
                              <div className="flex items-center gap-4 mb-6">
                                <box.icon size={32} className="opacity-50 group-hover:opacity-100 transition-opacity" />
                                <h4 className="font-mono text-xl tracking-wider">{box.title}</h4>
                              </div>
                              <p className="text-neutral-400 text-base leading-relaxed">{box.desc}</p>
                            </div>
                            <div className="mt-auto pt-6">
                              <p className="text-sm tracking-wider" style={{ color: box.color }}>{box.tagline}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>

                  {/* Notification Signup and Footer */}
                  <NotificationSignup isOpen={isOpen} setIsOpen={setIsOpen} />
                  <Footer 
                    handleFooterSubmit={handleFooterSubmit}
                    footerEmail={footerEmail}
                    setFooterEmail={setFooterEmail}
                    footerError={footerError}
                  />
                </main>
              </div>
            </>
          )}
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default memo(App);